<template>
  <div class="goods-header">
    <div class="sctp-pad-tb10">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>需求任务大厅</el-breadcrumb-item>
        <el-breadcrumb-item>需求详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <template v-if="notExist === true">
      <div style="padding: 50px 0;" class="sctp-bg-white">
        <empty :message="'需求不存在或已下架'"></empty>
      </div>
    </template>
    <template v-else>
      <div class="sctp-bg-white pad15" v-loading="!initViews">
        <el-row type="flex" :gutter="10">
          <el-col class="flex flex-col" :span="6">
            <div class="sctp-bg-white">
              <el-row>
                <el-image
                  style="width: 260px; height: 260px;"
                  :src="codeRequire && codeRequire.picture"
                  fit="cover"
                ></el-image>
              </el-row>
              <div class="pad-t10 flex flex-sb">
                <div>
                  <template v-if="!user || (codeRequire && codeRequire.userid !== user.userId)">
                    <a v-if="codeRequire && codeRequire.favorite"
                       class="sctp-color-main"
                       @click="doPrivileged(favoriteProductConfig.onClick)">
                      <i class="el-icon-star-on"></i>
                      已收藏
                    </a>
                    <a v-else
                       @click="doPrivileged(favoriteProductConfig.onClick)">
                      <i class="el-icon-star-off"></i>
                      收藏需求
                    </a>
                  </template>
                </div>
                <a @click="reportConfig.showReport">
                  <i class="el-icon-warning"></i>
                  举报
                </a>
              </div>
              <div class="view-control-wrap" v-if="false">
                <div class="sctp-tc">
                  <div class="sctp-mar-b5">浏览数</div>
                  <div class="number">{{ codeRequire && codeRequire.hits || 0 }}</div>
                </div>
                <div class="sctp-tc">
                  <div class="sctp-mar-b5">沟通数</div>
                  <div class="number">{{ codeRequire && codeRequire.views || 0 }}</div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col class="flex flex-col" :span="13">
            <div class="sctp-bg-white pad-lr10">
              <div class="sctp-pad-b10 fz-24">
                {{ codeRequire && codeRequire.title }}
              </div>
              <div
                class="sctp-mar-tb15 sctp-bg-f8 sctp-pad-tb15 sctp-pad-lr20 sctp-info sctp-font-14 ">
                <div class="sctp-flex-item-1 sctp-flex flex-sb" style="align-items: center;">
                  <template v-if="codeRequire">
                    <div class="sctp-red sctp-font-28 sctp-mar-r15">
                      {{
                        codeRequire.budget_scope && `预算：¥${codeRequire.budget_scope}`
                        || '无预算'
                      }}
                    </div>
                    <template
                      v-if="codeRequire.reqflag === 3 && codeRequire.expectdate > new Date().getTime()">
                      <div>
                        投标进行中
                      </div>
                    </template>
                  </template>
                </div>
              </div>
              <div class="sctp-pad-tb10 sctp-info">
                需求编号：{{ codeRequire && codeRequire.requireid }}
              </div>
              <div class="sctp-pad-tb10 sctp-info">
                需求分类：{{ codeRequire && codeRequire.reqFlagStr }}+{{
                  codeRequire && codeRequire.codeTypeInfo.typename
                }}
              </div>
              <div class="sctp-pad-tb10 sctp-info">
                截止时间：{{ dateToString(codeRequire && codeRequire.expectdate, 'yyyy-MM-dd') }}
              </div>
              <div class="sctp-pad-tb10 sctp-info" v-if="false">
                需求附件：
                <a target="_blank" :href="codeRequire && codeRequire.attachment">
                  <i class="el-icon-document mg-r5"></i>
                  {{
                    codeRequire && codeRequire.attachment && codeRequire.attachment.substring(
                      codeRequire.attachment.lastIndexOf('/') + 1)
                  }}
                </a>
              </div>
              <div class="pad-t20">
                <template v-if="!user || (codeRequire && codeRequire.userid !== user.userId)">
                  <el-button size="medium" type="primary" @click="doPrivileged(showPhoneMethod)">
                    查看联系方式
                  </el-button>
                  <template
                    v-if="codeRequire && codeRequire.reqflag && recommendMeConfig.type[codeRequire.reqflag]">
                    <el-button size="medium" type="primary"
                               @click="doPrivileged(recommendMeConfig.type[codeRequire.reqflag].action)">
                      {{
                        recommendMeConfig.type[codeRequire.reqflag].label
                      }}
                    </el-button>
                  </template>
                  <template v-if="codeRequire && codeRequire.reqflag === 3">
                    <el-button
                      size="medium"
                      @click="doPrivileged(tenderConfig.scrollForm)"
                    >
                      我要投标
                    </el-button>
                  </template>
                </template>
              </div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="sctp-bg-white pad15" style="border: 1px solid #eee;">
              <div class="sctp-flex">
                <el-avatar class="sctp-mar-r10"
                           :src="codeRequire && codeRequire.userInfo && codeRequire.userInfo.head_imgurl"></el-avatar>
                <div class="sctp-flex-item-1">
                  <div>{{
                      codeRequire && codeRequire.userInfo && codeRequire.userInfo.usernice
                    }}
                  </div>
                </div>
              </div>
              <div class="pad-tb10" style="line-height: 2;">
                <div>
                  <span>所在地区：</span>
                  <span>{{
                      codeRequire && codeRequire.userInfo && codeRequire.userInfo.provincename
                    }}{{
                      codeRequire && codeRequire.userInfo && codeRequire.userInfo.cityname
                    }}</span>
                </div>
                <div>
                  <span>商户类型：</span>
                  <span>{{
                      codeRequire && codeRequire.userInfo && (codeRequire.userInfo.currentType)
                    }}</span>
                  <template
                    v-if="codeRequire && codeRequire.userInfo && codeRequire.userInfo.currentType">
                    <span class="sctp-info">
                    <template v-if="codeRequire && codeRequire.userInfo.currentType.includes('个人')">
                      (身份证)
                    </template>
                    <template v-else>
                      (营业执照)
                    </template>
                    </span>
                  </template>
                </div>
                <div>
                  <span>认证类型：</span>
                  <span>
                    <template v-if="codeRequire && codeRequire.userInfo">
                      <template v-if="codeRequire.userInfo.phone">
                        <i class="fa fa-mobile fz-18 mg-r5" aria-hidden="true"></i>
                      </template>
                      <template v-if="codeRequire.userInfo.mailboxAuthflag === 1">
                       <i class="fa fa-envelope fz-18 mg-r5" aria-hidden="true"></i>
                      </template>
                      <template v-if="codeRequire.userInfo.idcardAuthflag === 1">
                       <i class="fa fa-id-card fz-16 mg-r5" aria-hidden="true"></i>
                      </template>
                    </template>
                  </span>
                </div>
              </div>
              <div class="mg-t10">
                <el-button @click="onPublishBtnClick" size="mini">发布类似需求</el-button>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="10">
          <el-col :span="19">
            <div class="pad-b10 pad-t20 sctp-info">
              <div class="fz-16 mg-b10 pad-t20" style="border-top: 1px #aaa dashed;">需求描述：</div>
              <div class="pad-lr20 fz-14 flex flex-sb">
                <div>
                  <span class="mg-r15">浏览数：{{ codeRequire && codeRequire.hits || 0 }}次</span>
                  <span>沟通数：{{ codeRequire && codeRequire.views || 0 }}次</span>
                </div>
                <div>
                  参考附件：
                  <template v-if="codeRequire && codeRequire.attachment">
                    <a target="_blank" :href="codeRequire && codeRequire.attachment">
                      <i class="el-icon-document mg-r5"></i>
                      {{
                        codeRequire && attachmentName(codeRequire.attachment, codeRequire.requireid)
                      }}
                    </a>
                  </template>
                  <template v-else>
                    无
                  </template>
                </div>
              </div>
              <div class="require-desc">
                <div class="sctp-flex-item-1 rich-text sctp-info">{{
                    codeRequire && codeRequire.reqdesc
                  }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-row class="sctp-mar-t10" :gutter="10">
        <el-col :span="18">
          <div class="">

            <div class="card pad-lr15">
              <el-tabs v-model="tabsOfTenderAndLeave">
                <el-tab-pane v-if="codeRequire && codeRequire.reqflag === 3"
                             :label="`招标服务商(${tenderConfig.pageConfig.total || 0})`" name="tender">
                  <template v-if="tenderConfig.data && tenderConfig.data.length > 0">
                    <div class="card">
                      <el-row :gutter="10">
                        <el-col :span="24"
                                v-for="tender in tenderConfig.data"
                                :key="tender.id">
                          <div class="flex pad15 fc-info">
                            <el-avatar
                              class="sctp-mar-r10" :size="30"
                              :src="tender.shopLogo"
                              fit="cover"></el-avatar>
                            <div class="flex-item1">
                              <div class="flex flex-sb">
                        <span class="sctp-bold fz-14 mg-b10">{{
                            tender.shopName
                          }}</span>
                                <span>{{ dateToString(tender.createTime) }}</span>
                              </div>
                              <template
                                v-if="tender.viewer === 2 && (!user || user.userId !== tender.userId)">
                                仅雇主可见投标内容
                              </template>
                              <template v-else>
                                <div>
                                  <span>报价：{{ Number(tender.price).toMoney() }}元</span>
                                  <span class="mg-l10">开发周期：{{ tender.day }}天</span>
                                </div>
                                <div>投标说明：</div>
                                <div class="rich-text" v-html="tender.mark"></div>
                              </template>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="24">
                          <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe">
                            <el-pagination
                              :current-page="tenderConfig.pageConfig.page"
                              :page-size="tenderConfig.pageConfig.limit"
                              @size-change="tenderConfig.pageConfig.handleSizeChange"
                              @current-change="tenderConfig.pageConfig.handleCurrentChange"
                              :page-sizes="tenderConfig.pageConfig.pageSizes"
                              layout="prev, pager, next, total, sizes"
                              :total="tenderConfig.pageConfig.total">
                            </el-pagination>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </template>
                </el-tab-pane>
                <el-tab-pane :label="`留言记录(${leaveMessageListCount || 0})`" name="leave">
                  <div class="card">
                    <el-row :gutter="10">
                      <el-col :span="24"
                              v-for="message in leaveMessageList"
                              :key="message.id">
                        <div class="flex pad15 fc-info">
                          <el-avatar
                            class="sctp-mar-r10" :size="30"
                            :src="message.userinfo && message.userinfo.headimage"
                            fit="cover"></el-avatar>
                          <div class="flex-item1">
                            <div class="flex flex-sb">
                        <span class="sctp-bold">{{
                            message.userinfo && message.userinfo.usernice
                          }}</span>
                              <span>{{ dateToString(message.createtime) }}</span>
                            </div>
                            <div class="sctp-flex sctp-flex-sb">
                              <div class="rich-text" v-html="message.content"></div>
                              <div v-if="message.userid == user.userId" @click="removeCodeRequireLeaveword(message.id)" style="cursor: pointer"><i class="el-icon-delete"></i></div>
                            </div>
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="24">
                        <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe">
                          <el-pagination
                            style="float: right"
                            background
                            layout="prev, pager, next"
                            @current-change="getCodeRequireLeaveword"
                            :current-page.sync="leaveMessageListPage"
                            :page-size="10"
                            :total="leaveMessageListCount">
                          </el-pagination>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-tab-pane>
              </el-tabs>

            </div>

            <div class="pad15 card" v-show="tabsOfTenderAndLeave === 'leave'">
              <el-row :gutter="10">
                <vue-h5-editor v-model="leaveMessage"></vue-h5-editor>
                <br>
                <el-button size="medium" type="primary"
                           @click="doPrivileged(addCodeRequireLeaveword)">提交
                </el-button>
              </el-row>
            </div>

            <div class="pad15 card" v-show="tabsOfTenderAndLeave === 'tender'">
              <el-form
                :model="tenderConfig.form"
                :rules="tenderConfig.formRules"
                ref="tenderConfigForm"
                id="tenderConfigForm"
                label-width="100px"
              >
                <el-form-item label="开发报价：" prop="price">
                  <el-input
                    style="width: 130px;"
                    v-model="tenderConfig.form.price"></el-input>
                  元
                </el-form-item>
                <el-form-item label="开发周期：" prop="day">
                  <el-input-number
                    style="width: 130px;"
                    size="small"
                    v-model="tenderConfig.form.day"
                    :min="1" :max="60"></el-input-number>
                  天
                </el-form-item>
                <el-form-item label="投标说明：" prop="mark">
                  <el-input
                    show-word-limit
                    resize="none"
                    :rows="5"
                    :maxlength="1000"
                    type="textarea"
                    v-model="tenderConfig.form.mark"></el-input>
                  <div class="el-upload__tip">(请勿透露任何联系方式)</div>
                </el-form-item>
                <el-form-item prop="viewer">
                  <el-radio-group v-model="tenderConfig.form.viewer">
                    <el-radio :label="1">公开投标内容</el-radio>
                    <el-radio :label="2">仅雇主可见投标内容</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="sctp-tc">
                  <el-button @click="tenderConfig.onSubmitClick" size="small" type="primary">我要投标
                  </el-button>
                </div>
              </el-form>
            </div>

          </div>
        </el-col>
        <el-col :span="6">
          <template v-if="recommendRequire && recommendRequire.length > 0">
            <div class="sctp-bg-white pad-lr10 pad-b10">
              <el-row :gutter="10">
                <el-col :span="24" class="sctp-flex sctp-flex-sb sctp-pad10">
                  <div>相似推荐</div>
                  <div></div>
                </el-col>
                <template v-for="(recommendItem,index) in recommendRequire">
                  <a target="_blank" :key="recommendItem.requireid"
                     :href="'/requirement/view/' + recommendItem.requireid">
                    <el-col :span="24" class="recommend-item">
                      <div class="sctp-flex">
                        <el-image
                          class="sctp-mar-r10"
                          :src="recommendItem.picture"
                          fit="cover"
                          style="width:100px;height:100px;"
                        ></el-image>
                        <div class="sctp-flex-item-1">
                          <div class="flex flex-sb flex-col full">
                            <div>
                              <div class="sctp-ellipsis--l3">{{ recommendItem.title }}</div>
                              <div>{{
                                  recommendItem.reqFlagStr
                                }}+{{ recommendItem.codeTypeInfo.typename }}
                              </div>
                            </div>
                            <div class="sctp-font-12 sctp-mar-t5"><span>价格：</span><span
                              class="sctp-red">{{
                                recommendItem.budget_scope ? '¥' + recommendItem.minbudget + '-'
                                  + '¥' + recommendItem.maxbugget : '无预算'
                              }}</span></div>
                          </div>
                        </div>
                      </div>
                      <template v-if="index !== recommendRequire.length - 1">
                        <div class="divider"></div>
                      </template>
                    </el-col>
                  </a>
                </template>
              </el-row>
            </div>
          </template>
        </el-col>
      </el-row>
      <el-dialog
        center
        title="查看联系方式"
        width="600px"
        :visible.sync="viewPhonePayDialog"
      >
        <pay :pay-amount="viewContactPrice"
             @pay="onViewPhonePay"
             :can-use-reward="true"
        ></pay>
      </el-dialog>
      <el-dialog
        center
        title="需求信息内容举报"
        :visible.sync="reportConfig.show"
      >
        <report
          @success="reportConfig.hiddenReport"
          :target="{
             title:codeRequire && codeRequire.title,
             id: codeRequire && codeRequire.requireid
          }"
          :type="1"></report>
      </el-dialog>
      <el-dialog
        center
        :title="recommendMeConfig.title"
        :visible.sync="recommendMeConfig.showDialog"
      >
        <custom-transfer
          :titles="recommendMeConfig.titles"
          :max="5"
          :empty-tips="recommendMeConfig.emptyTips"
          class="recommendRequireTransfer"
          v-model="recommendMeConfig.value"
          :data="recommendMeConfig.data">
          <template slot="left-footer">
            <el-pagination
              :pager-count="3"
              :current-page="recommendMeConfig.pageConfig.page"
              :page-size="recommendMeConfig.pageConfig.limit"
              @size-change="recommendMeConfig.pageConfig.handleSizeChange"
              @current-change="recommendMeConfig.pageConfig.handleCurrentChange"
              :page-sizes="recommendMeConfig.pageConfig.pageSizes"
              layout="prev, pager, next, total, sizes"
              :total="recommendMeConfig.pageConfig.total"
            ></el-pagination>
          </template>
        </custom-transfer>
        <div class="mg-t20 sctp-tc">
          <el-button
            @click="recommendMeConfig.submit()"
            type="primary"
            size="small"
            :disabled="!recommendMeConfig.value || recommendMeConfig.value.length === 0"
          >提交
          </el-button>
        </div>
      </el-dialog>
      <el-dialog
        center
        title="联系方式"
        width="500px"
        :visible.sync="viewPhoneDialogConfig.show"
      >
        <div>
          <div class="flex pad-tb5">
            <div class="sctp-tr" style="width: 100px;">手机号码：</div>
            <div>{{ codeRequire && codeRequire.phone || '未设置' }}</div>
          </div>
          <div class="flex pad-tb5">
            <div class="sctp-tr" style="width: 100px;">Email邮箱：</div>
            <div>{{
                codeRequire && codeRequire.userInfo && codeRequire.userInfo.email
                && codeRequire.userInfo.mailboxAuthflag === 1 && codeRequire.userInfo.email || '未设置'
              }}
            </div>
          </div>
          <div class="flex pad-tb5">
            <div class="sctp-tr" style="width: 100px;">QQ号：</div>
            <div>{{
                codeRequire && codeRequire.userInfo && codeRequire.userInfo.qqcode
                && codeRequire.userInfo.qqcode_authflag === 1 && codeRequire.userInfo.qqcode
                || '未设置'
              }}
            </div>
          </div>
          <div class="flex pad-tb5">
            <div class="sctp-tr" style="width: 100px;">微信号：</div>
            <div>{{
                codeRequire && codeRequire.userInfo && codeRequire.userInfo.wxcode
                && codeRequire.userInfo.wxqrcode_authflag === 1 && codeRequire.userInfo.wxcode
                || '未设置'
              }}
            </div>
          </div>
          <template v-if="codeRequire && codeRequire.userInfo && codeRequire.userInfo.wxrqrcode_imgurl
                && codeRequire.userInfo.wxrqrcode_authflag === 1">
            <div class="flex pad-tb5">
              <div class="sctp-tr" style="width: 100px;">微信二维码：</div>
              <div>
                <el-image
                  :src="codeRequire.userInfo.wxqrcode"
                  style="width: 80px;height: 80px;"
                  fit="contain"
                ></el-image>
              </div>
            </div>
          </template>
          <div class="fz-12 fc-info">
            注：以上由卖家自行填写，请及时联系，联系时请说明信息来源。
          </div>
        </div>
      </el-dialog>
      <el-dialog
        center
        title="我要投标"
        width="600px"
        :visible.sync="tenderConfig.show"
      >
        <el-form
          :model="tenderConfig.form"
          :rules="tenderConfig.formRules"
          ref="tenderConfigForm"
          label-width="100px"
        >
          <el-form-item label="开发报价：" prop="price">
            <el-input
              style="width: 130px;"
              v-model="tenderConfig.form.price"></el-input>
            元
          </el-form-item>
          <el-form-item label="开发周期：" prop="day">
            <el-input-number
              style="width: 130px;"
              size="small"
              v-model="tenderConfig.form.day"
              :min="1" :max="60"></el-input-number>
            天
          </el-form-item>
          <el-form-item label="投标说明：" prop="mark">
            <el-input
              show-word-limit
              resize="none"
              :rows="5"
              :maxlength="1000"
              type="textarea"
              v-model="tenderConfig.form.mark"></el-input>
            <div class="el-upload__tip">(请勿透露任何联系方式)</div>
          </el-form-item>
          <el-form-item prop="viewer">
            <el-radio-group v-model="tenderConfig.form.viewer">
              <el-radio :label="1">投标完全公开</el-radio>
              <el-radio :label="2">投标仅雇主可见</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="sctp-tc">
            <el-button @click="tenderConfig.show = false" size="small">取消</el-button>
            <el-button @click="tenderConfig.onSubmitClick" size="small" type="primary">提交
            </el-button>
          </div>
        </el-form>
      </el-dialog>
    </template>
    <el-dialog
      title="查看联系方式需支付服务费"
      :visible.sync="openPayAfterFlag"
      width="26%"
      class="register-dialog"
      center>
      <div>
        <div style="text-align: center;color: red;font-size: 20px">{{Number(viewContactPrice).toMoney()}}元</div>
        <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
        <div class="flex flex-center">
          <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="codeRequire && codeRequire.phone"></i>
            <i class="el-icon-error" v-else></i>手机号</div>
          <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="codeRequire && codeRequire.userInfo
      && codeRequire.userInfo.email && codeRequire.userInfo.mailboxAuthflag === 1"></i>
            <i class="el-icon-error" v-else></i>邮箱</div>
          <div class="sctp-mar-r10">
            <i class="el-icon-success sctp-color-main" v-if="codeRequire && codeRequire.userInfo
      && codeRequire.userInfo.qqcode && codeRequire.userInfo.qqcode_authflag === 1"></i>
            <i class="el-icon-error" v-else></i>QQ</div>
          <div class="sctp-mar-r10">
            <i class="el-icon-success sctp-color-main" v-if="codeRequire && codeRequire.userInfo
      && codeRequire.userInfo.wxcode && codeRequire.userInfo.wxqrcode_authflag === 1"></i>
            <i class="el-icon-error" v-else></i>微信</div>
        </div>
      </div>
      <div class="sctp-flex sctp-flex-center" style="padding-top: 10px">
        <el-checkbox v-model="read">
          我已阅读
          <a @click.prevent="centerDialogVisible = true" class="sctp-color-main">《平台服务条款》</a>
          并同意
        </el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">

        <el-button @click="openPayAfterFlag = false" >取消</el-button>
        <el-button type="primary" @click="openPay()">支付</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="平台服务条款"
      :visible.sync="centerDialogVisible"
      class="register-dialog"
      center>
      <div class="rich-text" v-html="productReleaseTerms"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {collection, common, demand, shop} from "@/apis/index";
import Random from "@/resources/js/random";
import asyncValidator from "@/resources/js/async-validator";
import Vuex from "vuex";

export default {
  name: 'coreRequireView',
  components: {
    pay: () => import('@CMP/common/BuyPay'),
    vueH5Editor: () => import('@CMP/vueh5editor/VueH5Editor'),
    report: () => import('@CMP/common/UserReport'),
    empty: () => import('@CMP/common/EmptyData.vue'),
    CustomTransfer: () => import('@PAGE/home-center/requirement/Recommend.vue'),
  },
  props: {
    id: {
      type: Number
    }
  },
  data() {
    return {
      centerDialogVisible:false,
      productReleaseTerms:null,
      openPayAfterFlag:false,
      read:false,
      tabsOfTenderAndLeave: 'leave',
      initViews: false,
      notExist: null,
      // 查看联系方式金额
      viewContactPrice: 0,
      codeRequire: null,
      viewPhoneDialogConfig: {
        show: false,
      },
      showPhone: false,
      leaveMessageList: [],
      leaveMessageListCount: null,
      leaveMessageListPage: 1,
      leaveMessage: null,
      viewPhonePayDialog: false,
      reportConfig: {
        show: false,
        showReport: () => {
          this.reportConfig.show = true
        },
        hiddenReport: () => {
          this.reportConfig.show = false
        }
      },
      recommendRequire: null,
      favoriteProductConfig: {
        onClick: () => {
          if (!this.favoriteProductConfig.canClick) {
            return;
          }
          this.favoriteProductConfig.canClick = false;
          collection.addFavorite({
            userId: (this.user && this.user.userId) || null,
            targetId: this.id,
            targetType: 3
          }).then(res => {
            const {retdata} = res;
            this.codeRequire.favorite = retdata;
            if (retdata) {
              this.$message.success('收藏成功')
            } else {
              this.$message.success('取消收藏成功')
            }
          }).finally(() => {
            this.favoriteProductConfig.canClick = true;
          })
        },
        canClick: true,
      },
      recommendMeConfig: {
        reqFlag: null,
        showDialog: false,
        title: '',
        titles: [],
        emptyTips: '',
        pageConfig: {
          page: 1,
          limit: 10,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 10),
          handleSizeChange: (limit) => {
            this.pageConfig.page = 1;
            this.pageConfig.limit = limit;
            this.recommendMeConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.pageConfig.page = page;
            this.recommendMeConfig.loadData();
          }
        },
        data: [],
        loadData: () => {
          let {reqFlag, pageConfig: {page, limit}} = this.recommendMeConfig;
          demand.loadRecommendMeData({
            userId: this.user.userId,
            requireId: this.id,
            reqFlag,
            page, limit,
          }).then(res => {
            let {retdata: {data, value}, count = 0} = res;
            this.recommendMeConfig.data = retdata.map(item => {
              return {
                key: item.id,
                label: item.title,
                disabled: false,
              }
            });
            this.recommendMeConfig.value = value;
            this.recommendMeConfig.pageConfig.total = count;
          });
        },
        value: [],
        type: {
          1: {
            label: '我有软件源码', action: () => {
              this.recommendMeConfig.title = '推荐我的源码给用户购买';
              this.recommendMeConfig.titles = ['我发布的源码', '已推荐的源码'];
              this.recommendMeConfig.showDialog = true;
              this.recommendMeConfig.reqFlag = 1;
              this.recommendMeConfig.emptyTips = '您还未发布源码'
              this.recommendMeConfig.loadData();
            }
          },
          2: {
            label: '我有设计原稿', action: () => {
              this.recommendMeConfig.title = '推荐我的设计原稿给用户购买';
              this.recommendMeConfig.titles = ['我发布的设计原稿', '已推荐的设计原稿'];
              this.recommendMeConfig.showDialog = true;
              this.recommendMeConfig.reqFlag = 2;
              this.recommendMeConfig.emptyTips = '您还未发布设计稿'
              this.recommendMeConfig.loadData();
            }
          },
          3: {
            label: '推荐我的服务', action: () => {
              this.recommendMeConfig.title = '推荐我的服务给用户购买';
              this.recommendMeConfig.titles = ['我发布的服务', '已推荐的服务'];
              this.recommendMeConfig.showDialog = true;
              this.recommendMeConfig.reqFlag = 3;
              this.recommendMeConfig.emptyTips = '您还未发布服务'
              this.recommendMeConfig.loadData();
            }
          },
          4: {
            label: '我有技术文档', action: () => {
              this.recommendMeConfig.title = '推荐我的文档给用户购买';
              this.recommendMeConfig.titles = ['我发布的文档', '已推荐的文档'];
              this.recommendMeConfig.showDialog = true;
              this.recommendMeConfig.reqFlag = 4;
              this.recommendMeConfig.emptyTips = '您还未发布文档'
              this.recommendMeConfig.loadData();
            }
          }
        },
        submit: () => {
          demand.submitRecommendMeData({
            userId: this.user.userId,
            reqFlag: this.recommendMeConfig.reqFlag,
            value: this.recommendMeConfig.value,
            requireId: this.id
          }).then(res => {
            this.recommendMeConfig.showDialog = false;
            this.$message.success('提交成功');
          });
        }
      },
      tenderConfig: {
        show: false,
        scrollForm: () => {
          this.tabsOfTenderAndLeave = 'tender';
          this.$nextTick(() => {
            document.getElementById("tenderConfigForm").scrollIntoView({
              behavior: 'smooth',
              block: "center"
            });
          })
        },
        showDialog: () => {
          if (!this.user.shopRegister) {
            this.$confirm('您还不是服务商无法投标，请先进行商家入驻后再投标', '提示', {
              showCancelButton: true,
              showConfirmButton: true,
              cancelButtonText: '暂不入驻',
              confirmButtonText: '前去入驻',
            }).then(res => {
              this.$router.push({
                path: '/shop/index'
              });
            });
            return;
          }
          this.tenderConfig.show = true;
        },
        loadData: () => {
          let {page, limit} = this.tenderConfig.pageConfig;
          demand.tenderList({
            page, limit,
            requireId: this.id,
            userId: (this.user && this.user.userId) || null,
          }).then(res => {
            let {retdata, count = 0} = res;
            this.tenderConfig.data = retdata;
            this.tenderConfig.pageConfig.total = count;
          })
        },
        pageConfig: {
          page: 1,
          limit: 10,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 10),
          handleSizeChange: (limit) => {
            this.pageConfig.page = 1;
            this.pageConfig.limit = limit;
            this.tenderConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.pageConfig.page = page;
            this.tenderConfig.loadData();
          }
        },
        data: [],
        form: {
          price: null,
          day: null,
          mark: null,
          viewer: null,
        },
        formRules: {
          price: [
            {required: true, message: '价格必填', trigger: ['blur', 'change']},
            {validator: asyncValidator.validMoney, message: '金额数字不合法', trigger: ['blur', 'change']},
          ],
          day: [
            {required: true, message: '周期必填', trigger: ['blur', 'change']},
          ],
          mark: [
            {required: true, message: '说明必填', trigger: ['blur', 'change']},
          ],
          viewer: [
            {required: true, message: '此项必选', trigger: ['blur', 'change']},
          ],
        },
        onSubmitClick: () => {
          this.$refs.tenderConfigForm.validate()
          .then(res => {
            this.tenderConfig.doSubmit();
          })
        },
        doSubmit: () => {
          let {form} = this.tenderConfig;
          demand.serviceTender({
            userId: this.user.userId,
            requireId: this.id,
            price: form.price,
            day: form.day,
            mark: form.mark,
            viewer: form.viewer,
          }).then(res => {
            this.$message.success('投标成功!');
            this.tenderConfig.show = false;
            this.tenderConfig.loadData();
            this.$refs.tenderConfigForm.resetFields();
          }).catch(res => {
            if (res && res.retCode === '9502') {
              this.$confirm('您还不是认证的服务商，暂时不能投标，请先完成入驻认证！', '', {
                cancelButtonText: '取消',
                confirmButtonText: '去入驻',
                center: true,
              }).then(res => {
                this.$router.push({
                  path: '/shop'
                })
              })
            }
          })
        },
      }
    }
  },
  watch: {
    id: {
      handler() {
        this.getCodeRequireDetail();
        this.getCodeRequireLeaveword()
      }
    }
  },
  methods: {
    onPublishBtnClick() {
      let {codeRequire} = this;
      this.goPage({
        path: '/submit-requirement',
        name: '发布需求',
        params: {
          postSimilar: {
            reqflag: codeRequire.reqflag,
            reqtypeid: codeRequire.reqtypeid,
            title: codeRequire.title,
            budget_scope: codeRequire.budget_scope,
            expectdate: codeRequire.expectdate,
            reqdesc: codeRequire.reqdesc,
          },
        },
      })
    },
    getCodeRequireDetail() {
      demand.detail({
        id: this.id,
        userId: (this.user && this.user.userId) || null
      }).then(res => {
        let {retdata} = res;
        demand.incrementViews({
          id: this.id,
          userId: (this.user && this.user.userId) || null
        });
        const {viewContactPrice = 0, sctpCodeRequire, sctpCodeType, sctpUserInfo, sctpCodeRequireJoin} = retdata;
        // 保存浏览历史记录
        common.viewHistory({
          userId: (this.user && this.user.userId) || null,
          type: 1,
          id: this.id,
          title: sctpCodeRequire.title
        });
        if (sctpCodeRequire.reqflag !== 3) {
          this.tabsOfTenderAndLeave = 'leave';
        }
        this.codeRequire = sctpCodeRequire;
        this.codeRequire.codeTypeInfo = sctpCodeType;
        this.codeRequire.userInfo = sctpUserInfo;
        this.showPhone = sctpCodeRequireJoin;
        this.viewContactPrice = viewContactPrice;
      }).catch((err) => {
        if (err.retCode === '9000') {
          this.notExist = true;
        }
        return Promise.reject(err);
      }).finally(() => {
        this.initViews = true;
      });
    },
    showPhoneMethod() {
      let {codeRequire, viewContactPrice, showPhone} = this;
      if (!codeRequire && !viewContactPrice) {
        return;
      }
      if (viewContactPrice == 0) {
        this.viewPhoneDialogConfig.show = true;
        return;
      }
      common.contactClickHistory({
        userId: this.user.userId,
        id: this.id,
        type: 2,
      });
      this.openPayAfterFlag = true
      // this.$confirm(`<div class="">
      //         <div class="sctp-red sctp-font-18">${viewContactPrice.toMoney()}元</div>
      //         <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
      //         <div class="flex flex-center">
      //         <div class="sctp-mar-r10">${codeRequire && codeRequire.phone
      //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}手机号</div>
      //         <div class="sctp-mar-r10">${codeRequire && codeRequire.userInfo
      // && codeRequire.userInfo.email && codeRequire.userInfo.mailboxAuthflag === 1
      //   ? '<i class="el-icon-success sctp-color-main"></i>'
      //   : '<i class="el-icon-error"></i>'}邮箱</div>
      //         <div class="sctp-mar-r10">${codeRequire && codeRequire.userInfo
      // && codeRequire.userInfo.qqcode && codeRequire.userInfo.qqcode_authflag === 1
      //   ? '<i class="el-icon-success sctp-color-main"></i>'
      //   : '<i class="el-icon-error"></i>'}QQ</div>
      //         <div class="sctp-mar-r10">${codeRequire && codeRequire.userInfo
      // && codeRequire.userInfo.wxcode && codeRequire.userInfo.wxqrcode_authflag === 1
      //   ? '<i class="el-icon-success sctp-color-main"></i>'
      //   : '<i class="el-icon-error"></i>'}微信</div>
      //         </div>
      //         </div>`, {
      //   dangerouslyUseHTMLString: true,
      //   title: '查看联系方式需支付服务费',
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   center: true
      // }).then(() => {
      //   this.viewPhonePayDialog = true
      // });
    },
    openPay(){
      if (!this.read){
        this.$message.warning('请确认阅读条款');
        return
      }
      this.openPayAfterFlag = false
      this.viewPhonePayDialog = true
    },
    onViewPhonePay({payWay}) {
      shop.viewContactInformation({
        payWay: payWay,
        userId: this.user.userId,
        eId: this.id,
        eType: 3,
      }).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.showPhone = true;
        this.viewPhonePayDialog = false;
        this.viewPhoneDialogConfig.show = true;
      })
    },
    getCodeRequireLeaveword() {
      this.$request.post({
        reqcode: '1074',
        reqdesc: '获取需求评论',
        page: this.leaveMessageListPage,
        limit: 8,
        requireId: this.id,
        userId: (this.user && this.user.userId) || null,
      }).then(res => {
        let {retdata, count = 0} = res;
        this.leaveMessageList = retdata;
        this.leaveMessageListCount = count;
      })
    },
    addCodeRequireLeaveword() {
      if (!this.leaveMessage) {
        this.$message({
          type: 'error',
          message: '请输入评论内容'
        });
        return
      }
      this.$request.post({
        reqcode: '1073',
        reqdesc: '添加需求评论',
        sctpCodeRequireLeaveword: {
          require: this.id,
          userid: this.user.userId,
          content: this.leaveMessage
        }
      }).then(res => {
        this.leaveMessage = '';
        this.$message({
          type: 'success',
          message: '评论成功'
        });
        this.getCodeRequireLeaveword()
      })
    },
    removeCodeRequireLeaveword(ids) {
      this.$confirm('确认删除该评论吗',{
        title: '删除评论',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(()=>{
        this.$request.post({
          reqcode: '1273',
          reqdesc: '删除评论',
          requireId: this.id,
          userId:this.user.userId,
          id:ids
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除评论成功'
          });
          this.getCodeRequireLeaveword()
        })
      })
    },
    getRecommendRequire() {
      return demand.recommendForRequire({
        requireId: this.id
      }).then(res => {
        let {retdata} = res;
        this.recommendRequire = retdata;
      });
    },
    getProductReleaseTerms() {
      common.loadProtocol({
        protocolName: '平台服务条款'
      }).then(res => {
        const {retdata} = res;
        this.productReleaseTerms = retdata
      })
    },
  },
  computed: {
    ...Vuex.mapGetters({
      user: 'account/getUser'
    }),
    attachmentName() {
      return function (fileName, demandId) {
        if (!fileName) {
          return '';
        }
        let suffix = fileName.substring(fileName.lastIndexOf('.'));
        fileName = fileName.substring(fileName.indexOf('-'), fileName.lastIndexOf('-')).replace(
          /-/g, '');
        return `${demandId}${fileName}${Random.instance.MathRand(4)}${suffix}`;
      }
    },
  },
  beforeMount() {
    this.getCodeRequireDetail();
    this.getCodeRequireLeaveword();
    this.getRecommendRequire();
    // 服务招标
    this.tenderConfig.loadData();
    //获取平台服务条款
    this.getProductReleaseTerms()
  }
}
</script>

<style scoped lang="scss">

.goods-header {
  ::v-deep {
    .el-dialog__body {
      overflow-y: auto;
    }

  }
  .recommendRequireTransfer {
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep {
      .el-transfer-panel {
        flex: 1;
      }

      .el-transfer__buttons {
        display: flex;
        flex-direction: column;
        padding: 0 15px;

        .el-transfer__button:first-child {
          margin-bottom: 20px;
        }

        .el-transfer__button:nth-child(2) {
          margin-left: 0;
        }
      }
    }
  }
}

.goods-header .shop-info div {
  line-height: 28px;
}

.block-info ::v-deep .el-col div {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.block-info ::v-deep .el-col:nth-child(odd) div {
  background-color: #f6f6f6;
}

.goods-score div {
  position: relative;
}

.goods-score div:not(:last-child):after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  background-color: red;
  width: 1px;
}

.goods-score div span:nth-child(1) {
  color: #F90;
  font-weight: bold;
  margin-bottom: 10px;
}

.require-desc {
  display: inline-block;
  white-space: pre-wrap;
  vertical-align: top;
  margin: 0 !important;
  padding: 10px 20px !important;
}

.auth_ok_icon {
  height: 12px;
  width: 12px;
  object-fit: cover;
  vertical-align: middle;
  margin-right: 3px;
}

.view-control-wrap {
  margin-top: 15px;
  display: flex;
  //border-style: solid;
  //border-width: 1px 0 1px 0;
  //border-color: #eee;

  & > div {
    flex: 1;
    padding: 8px 0;
    border-width: 1px;
    border-style: solid;
    border-color: #eee;

    .number {
      font-size: 20px;
      font-weight: bold;
    }
  }

  & > div:last-child {
    border-left: unset;

    .number {
      color: #F90;
    }
  }
}

.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin-bottom: 8px;
  margin-top: 5px;
  width: 100%;
}
</style>
